<script>


import FullTimeAdd from "@/views/admin/staffList/components/fullTimeAdd.vue";
import PartTimeAdd from "@/views/admin/staffList/components/partTimeAdd.vue";
import PracticeTimeAdd from "@/views/admin/staffList/components/practiceTimeAdd.vue";

export default {
  name: "staffAdd",
  components: {PracticeTimeAdd, PartTimeAdd, FullTimeAdd},
  data() {
    return {
      activeName: 'first'
    }
  },
  created() {
    this.$emit("setIndex", '/admin/staffList', "员工录入", true);
  },
  methods: {
    handleClick(tab) {
      this.activeName = tab.name
    }
  }
}
</script>

<template>
  <div class="wrapper padding-lr-sm">
    <div class="preview">

      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="全职员工信息录入" name="first">
          <full-time-add v-if="this.activeName === 'first'"/>
        </el-tab-pane>
        <el-tab-pane label="实习生信息录入" name="second">
          <practice-time-add v-if="this.activeName === 'second'"/>
        </el-tab-pane>
        <el-tab-pane label="兼职员工信息录入" name="third">
          <part-time-add v-if="this.activeName === 'third'"/>
        </el-tab-pane>
      </el-tabs>


    </div>
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  .preview {
    width: 100%;
    min-height: 500px;
    padding: 30px;
    border: 1px solid #DCDFE6;
    border-radius: 10px;


  }
}
</style>