<script>
import {contractTypeList, paymentTypeList, sexList, wayOfWorking, workingMode, workTypeList} from "@/utils/const";
import rules from "@/utils/rules";
import {shangJiList} from "@/api/entry";
import {openFile, upFile} from "@/config";
import { partTimeImport,} from "@/api/common";

export default {
  name: "partTimeAdd",
  data() {
    return {
      openFile: openFile,
      sexList: sexList,
      contractTypeList: contractTypeList,
      workTypeList: workTypeList,
      paymentTypeList: paymentTypeList,
      wayOfWorking: wayOfWorking,
      workingMode: workingMode,
      superior: [],
      upType: '',
      infoForm: {
        xingming: '',
        nicheng: '',
        xingbie: '',
        shoujihao: '',
          chushengnianyueri: '',
        shenfenzhenghao: '',
        youxiang: '',
        neibuyouxiang: '',
        jiatingdizhi: '',
        weixinhao: '',
        biyeyuanxiao: '',
        zaixiaozhuanye: '',
        zuigaoxueli: '',
        biyeshijian: '',
        xianjudizhi: '',
        zuizaoruzhishijian: '',
        jinjirenxingming: '',
        jinjirendianhua: '',
        yubenrenguanxi: '',
        kaihuhang: '',
        zhihangmingcheng: '',
        yinhangkahao: '',
        lishubumen: '',
        gangweimingcheng: '',
        zhijieshangji: '',
        ConsultantPeriod: '',
        jianzhixinchou: '',
        ruzhiriqi: '',
        gongzuodidian: '',
        ConsultantJoinDate: '',
        ConsultantResignDate: '',
        ConsultantWorkType: '',
        gongzuomoshi: '',
        offer_file: '',
        tongzhi_file: '',
        xieyi_file: '',


      },
      rules: {
        xingming: rules.mustInput,
        // nicheng: rules.mustInput,
        xingbie: rules.mustSelect,
        shoujihao: rules.phone,
        shenfenzhenghao: rules.mustInput,
        youxiang: rules.email,
        jiatingdizhi: rules.mustInput,
        // weixinhao: rules.mustInput,
        //
        // biyeyuanxiao: rules.mustInput,
        // zaixiaozhuanye: rules.mustInput,
        // zuigaoxueli: rules.mustInput,
        // biyeshijian: rules.mustDate,
        // xianjudizhi: rules.mustInput,
        // zuizaoruzhishijian: rules.mustDate,
        // jinjirenxingming: rules.mustInput,
        // jinjirendianhua: rules.phone,
        // yubenrenguanxi: rules.mustInput,
        // kaihuhang: rules.mustInput,
        // zhihangmingcheng: rules.mustInput,
        // yinhangkahao: rules.mustInput,

        lishubumen: rules.mustInput,
        gangweimingcheng: rules.mustInput,
        zhijieshangji: rules.mustSelect,
        ConsultantPeriod: rules.mustInput,
        jianzhixinchou: rules.mustInput,
        ruzhiriqi: rules.mustInput,
        gongzuodidian: rules.mustInput,
        ConsultantJoinDate: rules.mustInput,
        ConsultantResignDate: rules.mustInput,
        ConsultantWorkType: rules.mustSelect,
        gongzuomoshi: rules.mustSelect,


      },
      loading:false
    }
  },
  computed: {
    token() {
      return this.$store.state.token
    },
    actions() {
      return upFile
    },
    zhijieshangjiCn() {
      let _index = this.superior.findIndex(item => item.id === Number(this.staffInfoData.zhijieshangji))
      return this.superior[_index].name
    }
  },
  created() {
    this.shangJiList()
  },
  methods: {
    submitForm(formName) {
      this.loading = true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sureUp()
        }else{
          this.loading = false
        }
      });
    },
    async sureUp() {
      let _state = await partTimeImport(
          this.infoForm.xingming,
          this.infoForm.nicheng,
          this.infoForm.xingbie,
          this.infoForm.shoujihao,
          this.infoForm.chushengnianyueri,
          this.infoForm.shenfenzhenghao,
          this.infoForm.youxiang,
          this.infoForm.neibuyouxiang,
          this.infoForm.jiatingdizhi,
          this.infoForm.weixinhao,
          this.infoForm.biyeyuanxiao,
          this.infoForm.zaixiaozhuanye,
          this.infoForm.zuigaoxueli,
          this.infoForm.biyeshijian,
          this.infoForm.xianjudizhi,
          this.infoForm.zuizaoruzhishijian,
          this.infoForm.jinjirenxingming,
          this.infoForm.jinjirendianhua,
          this.infoForm.yubenrenguanxi,
          this.infoForm.kaihuhang,
          this.infoForm.zhihangmingcheng,
          this.infoForm.yinhangkahao,
          this.infoForm.lishubumen,
          this.infoForm.gangweimingcheng,
          this.infoForm.zhijieshangji,
          this.infoForm.ConsultantPeriod,
          this.infoForm.jianzhixinchou,
          this.infoForm.ruzhiriqi,
          this.infoForm.gongzuodidian,
          this.infoForm.ConsultantJoinDate,
          this.infoForm.ConsultantResignDate,
          this.infoForm.ConsultantWorkType,
          this.infoForm.gongzuomoshi,
          this.infoForm.offer_file,
          this.infoForm.tongzhi_file,
          this.infoForm.xieyi_file,
      )

      if (_state.status === 200) {
        this.loading = false
        this.$message.success(_state.info)
        this.$refs['infoForm'].resetFields();
      } else {
        this.loading = false
        this.$message.error(_state.info)
      }
    },
    //  获取上级
    async shangJiList() {
      const res = await shangJiList()
      this.superior = res.data
    },
    // 上传文件
    chooseFile(num) {
      this.upType = num
      // console.log(document.querySelector('.fileBtn'))
      document.querySelector('.fileBtn').click()
    },
    handleFileSuccess(file) {
      if (file.status === 200) {
        if (this.upType === 1) this.infoForm.offer_file = file.data
        if (this.upType === 2) this.infoForm.tongzhi_file = file.data
        if (this.upType === 3) this.infoForm.xieyi_file = file.data
        this.upType = ''
        this.$message.success('上传成功')
      } else {
        this.$message.error(file.msg)
      }
      this.$refs['fileBtn'].clearFiles()
    },
  },
  watch: {

  }
}
</script>

<template>
  <div>
    <el-form :model="infoForm"  v-loading="loading"
             label-width="160px"
             :rules="rules" ref="infoForm" class="add-form">
      <h3 class="preview-title margin-bottom">员工基本信息</h3>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="姓名" prop="xingming">
            <el-input v-model="infoForm.xingming" placeholder="请填写姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="昵称/英文" prop="nicheng">
            <el-input v-model="infoForm.nicheng" placeholder="请填写昵称/英文"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="性别" prop="xingbie">
            <el-select v-model="infoForm.xingbie" placeholder="请选择性别" class="w-all">
              <el-option
                  v-for="item in sexList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="手机" prop="shoujihao">
            <el-input v-model="infoForm.shoujihao" placeholder="请填写手机"></el-input>
          </el-form-item>
        </el-col>
          <el-col :span="12">
              <el-form-item label="出生年月日" prop="chushengnianyueri">
                  <el-date-picker align="center"
                                  class="w-all"
                                  value-format="yyyy-MM-dd"
                                  v-model="infoForm.chushengnianyueri"
                                  type="date"
                                  placeholder="请填写出生年月日">
                  </el-date-picker>
              </el-form-item>
          </el-col>
        <el-col :span="8">
          <el-form-item label="身份证/通行证" prop="shenfenzhenghao">
            <el-input v-model="infoForm.shenfenzhenghao" placeholder="请填写身份证/通行证"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="个人邮箱" prop="youxiang">
            <el-input v-model="infoForm.youxiang" placeholder="请填写邮箱"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="工作邮箱" prop="neibuyouxiang">
            <el-input v-model="infoForm.neibuyouxiang" placeholder="请填写邮箱"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="家庭地址" prop="jiatingdizhi">
            <el-input v-model="infoForm.jiatingdizhi" placeholder="请填写家庭地址"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="现居住地址" prop="xianjudizhi">
            <el-input v-model="infoForm.xianjudizhi" placeholder="请填写现居住地址"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="个人微信" prop="weixinhao">
            <el-input v-model="infoForm.weixinhao" placeholder="请填写个人微信"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="毕业学校" prop="biyeyuanxiao">
            <el-input v-model="infoForm.biyeyuanxiao" placeholder="请填写毕业学校"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="在校专业" prop="zaixiaozhuanye">
            <el-input v-model="infoForm.zaixiaozhuanye" placeholder="请填写在校专业"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="最高学历" prop="zuigaoxueli">
            <el-input v-model="infoForm.zuigaoxueli" placeholder="请填写最高学历"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="毕业时间" prop="biyeshijian">
            <el-date-picker align="center"
                            class="w-all"
                            v-model="infoForm.biyeshijian"
                            type="date"
                            placeholder="请填写毕业时间">
            </el-date-picker>
          </el-form-item>
        </el-col>

      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="紧急联系人姓名" prop="jinjirenxingming">
            <el-input v-model="infoForm.jinjirenxingming" placeholder="请填写紧急联系人姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="与本人关系" prop="yubenrenguanxi">
            <el-input v-model="infoForm.yubenrenguanxi" placeholder="请填写与本人关系"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="紧急联系人电话" prop="jinjirendianhua">
            <el-input v-model="infoForm.jinjirendianhua" placeholder="请填写紧急联系人电话"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="银行卡开户行" prop="kaihuhang">
            <el-input v-model="infoForm.kaihuhang" placeholder="请填写银行卡开户行"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="开户行支行名称" prop="zhihangmingcheng">
            <el-input v-model="infoForm.zhihangmingcheng" placeholder="请填写开户行支行名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="银行卡号" prop="yinhangkahao">
            <el-input v-model="infoForm.yinhangkahao" placeholder="请填写银行卡号"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <h3 class="preview-title margin-bottom">员工在职信息</h3>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="所属部门" prop="lishubumen">
            <el-input v-model="infoForm.lishubumen" placeholder="请填写所属部门"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="岗位" prop="gangweimingcheng">
            <el-input v-model="infoForm.gangweimingcheng" placeholder="请填写岗位"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="直接上级" prop="zhijieshangji">
            <el-select v-model="infoForm.zhijieshangji" placeholder="请选择直接上级" class="w-all">
              <el-option
                  v-for="item in superior"
                  :key="item.id"
                  :label="item.xingming"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="入职日期" prop="ruzhiriqi">
            <el-date-picker
                align="center"
                class="w-all"
                value-format="yyyy-MM-dd"
                v-model="infoForm.ruzhiriqi"
                type="date"
                placeholder="请选择入职日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="工作地点" prop="gongzuodidian">
            <el-input v-model="infoForm.gongzuodidian" placeholder="请填写工作地点"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="兼职薪酬" prop="jianzhixinchou">
            <el-input type="number" v-model="infoForm.jianzhixinchou" placeholder="请填写兼职薪酬">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>

      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="兼职时间（月）" prop="ConsultantPeriod">
            <el-input type="number" v-model="infoForm.ConsultantPeriod" placeholder="请填写兼职时间（月）">
              <template slot="append">月</template>
            </el-input>
          </el-form-item>
        </el-col>


        <el-col :span="8">
          <el-form-item label="工作方式" prop="ConsultantWorkType">
            <el-select v-model="infoForm.ConsultantWorkType" placeholder="请选择工作方式" class="w-all">
              <el-option
                  v-for="item in  wayOfWorking"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="工作模式" prop="gongzuomoshi">
            <el-select v-model="infoForm.gongzuomoshi" placeholder="请选择工作模式" class="w-all">
              <el-option
                  v-for="item in workingMode"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col  :span="12">
          <el-form-item
              label="兼职开始时间"
              prop="ConsultantJoinDate">
            <el-date-picker
                align="center"
                class="w-all"
                v-model="infoForm.ConsultantJoinDate"
                type="date" value-format="yyyy-MM-dd"
                placeholder="请选择开始日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col  :span="12">
          <el-form-item
              label="兼职结束时间"
              prop="ConsultantResignDate">
            <el-date-picker
                align="center"
                class="w-all"
                v-model="infoForm.ConsultantResignDate"
                type="date" value-format="yyyy-MM-dd"
                placeholder="请选择结束日期">
            </el-date-picker>
          </el-form-item>
        </el-col>

      </el-row>

      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="Offer Letter（附件）" prop="offer_file">
            <el-button class="template-btn margin-right" size="mini" type="primary"
                       @click="chooseFile(1)">上传
            </el-button>
            <el-link v-if="infoForm.offer_file" :href="openFile + infoForm.offer_file" target="_blank">
              已上传附件(点击查看)
            </el-link>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="入职通知（附件）" prop="tongzhi_file">
            <el-button class="template-btn margin-right" size="mini" type="primary"
                       @click="chooseFile(2)">上传
            </el-button>
            <el-link v-if="infoForm.tongzhi_file" :href="openFile + infoForm.tongzhi_file"
                     target="_blank">
              已上传附件(点击查看)
            </el-link>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="兼职协议（附件）" prop="xieyi_file">
            <el-button class="template-btn margin-right" size="mini" type="primary"
                       @click="chooseFile(3)">上传
            </el-button>

            <el-link v-if="infoForm.xieyi_file" :href="openFile + infoForm.xieyi_file"
                     target="_blank">
              已上传附件(点击查看)
            </el-link>
          </el-form-item>
        </el-col>

      </el-row>


      <el-form-item label-width="0" class="flex justify-around">
        <el-button class="submit-btn" type="primary" @click="submitForm('infoForm')">保存</el-button>
      </el-form-item>
    </el-form>
    <el-upload
        style="display: none;"
        ref="fileBtn"
        class="upload-demo"
        :action="actions"
        :data="{token}"
        :on-success="handleFileSuccess"
        :limit="1"
        :show-file-list="false"

    >
      <i class="fileBtn"></i>
    </el-upload>
  </div>

</template>

<style scoped lang="scss">
.preview-title {
  text-align: left;
  line-height: 60px;
  font-size: 22px;
}
</style>
